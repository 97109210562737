<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'gym',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },

  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      form: {
        status: true,
        title: '',
        address: '',
        since : '',
        descriptiontime : '',
        open_qym :  '',
        close_qym :  '',
        detail : { 
        qym_style : '',
        city :  '',
        price: '',
        second_price: ''
        },
        summary :  '',
        latitude : '',
        longitude : '',
        image: [],
        new_image: [],
        inputtag: [],
        facility: '',
        environment: '',
        option: [],
        env_option: [],
        website: '',
        call: '',
        recommend : ''    
      },

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 5,
        parallelUploads: 50,
        paramName: 'images',
        uploadMultiple: true,
        acceptedFiles: 'image/*',      
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      isEdit: false,
      status : {
        second_price : true
      }
    }
  },
  computed: {
    checkedOption() {
      return this.form.option.filter(item => item.checked).map(items => items.item)       
    },
    checkedEnv_Option() {
      return this.form.env_option.filter(item => item.checked).map(items => items.item)       
    }
  },
  validations: {
    form: {
      title: {
        required,
      },
      address: {
        required,
      },
      descriptiontime : {
        required
      },
      open_qym: {
        required,
      },
      close_qym: {
        required,
      },
      qym_stylee: {
        required,
      },
      city: {
        required,
      },
      price: {
        required,
      },
      facility: {
        required,
      },
      summary: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
      env_option: {
        required,
      },
      option: {
        required,
      },
      website: {
        required,
      },
      call: {
        required,
      }
    },
  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    'form.detail.price'(newValue) {

      if(Number(newValue) > 0){
         this.status.second_price = false
      } else {
         this.status.second_price = true
         this.form.detail.price = ""
         this.form.detail.second_price = ""
      }
      // ...
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.initialUpdateItem(id)    
  },
  methods: {
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },

    vfileAdded(file) {

      this.form.new_image = file

      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    async removeImage(picId){
      const request = axios.get(`/api/gym/removeImage/${this.$route.params.id}/${picId}`)
      
      await request.then(async (result) => {
          this.onCancel()
          this.$router.go(0)
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },

    async formSubmit() {
      this.doAjax()
      let body = {
        title : this.form.title,
        address : this.form.address,
        since : this.form.since,
        descriptiontime : this.form.descriptiontime,
        open_qym : this.form.open_qym,
        close_qym : this.form.close_qym,
        qym_style : this.form.detail.qym_style,
        city : this.form.detail.city,
        price : this.form.detail.price,
        second_price : this.form.detail.second_price,
        environment : await this.checkedEnv_Option,
        facility : await this.checkedOption,
        summary : this.form.summary,
        latitude : this.form.latitude,
        longitude : this.form.longitude,
        website : this.form.website,
        call : this.form.call,
        recommend : this.form.recommend  
      }
      
      const data = new FormData()
      if (!this.ismanuallyadd) {
        for (const i of Object.keys(this.form.new_image)) {

          data.append('image', this.form.new_image[i])
        }
      }
      console.log(this.form.new_image)

      data.append('data', JSON.stringify(body))

      const paramsId = this.$route.params.id
      const request = axios.post(`/api/gym/edit/${paramsId}`, data)

      await request.then(async (result) => {
          this.onCancel()
          this.$toast.success('Saved successfully')
          this.$router.push('/gym')
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },


    async initialUpdateItem(id) {
      if (!id) return
       console.log("log set =>" + id)
      await axios.get(`api/gym/edit/${id}`).then((res) => {
    
        this.form = { ...this.form, ...res.data }      
      })
    /*   await axios.get(`api/content/${id}`).then((res) => {
        this.form = { ...this.form, ...res.data }

        this.form.inputtag = res.data.hashtag
        this.isEdit = true

        var file = { size: 123, name: 'Icon', type: 'image/png' }
        if (this.form.img_url) {
          var url = `${this.baseImageUrl}/${this.form.img_url}`
          this.$refs.myVueDropzone.manuallyAddFile(file, url)
        }
      }) */

      
    },

  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">gym</h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Address</label>
                      <b-form-input
                        id="address"
                        v-model="form.address"
                        placeholder="Enter address"
                        name="address"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    
                    <b-form-group label-cols-lg="0">
                      <label>Description Time</label>
                      <b-form-input
                        id="descriptiontime"
                        v-model="form.descriptiontime"
                        placeholder="Enter description time "
                        name="descriptiontime"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Open:</label>
                        <b-form-timepicker v-model="form.open_qym" locale="en"></b-form-timepicker>

                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Close</label>
                        <b-form-timepicker v-model="form.close_qym" locale="en"></b-form-timepicker>
                      </b-col>
                    </b-row>
                    <!-- <b-form-group label-cols-lg="0">
                      <label>Short description</label>
                      <b-form-input
                        placeholder="Short description..."
                        v-model="form.short_description"
                      ></b-form-input>
                    </b-form-group> -->
                    <b-form-group label-cols-lg="0">
                      <label>Summary</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.summary"
                        placeholder="Enter summary..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Image</label>
                      <div>
                      <b-card-group deck>
                      <div v-for="item in this.form.image" :key="item._id">
                      <b-card                     
                       :img-src="`${baseImageUrl}/${item.url}`"
                       img-alt="Image"
                       img-top
                       tag="article"
                       style="max-width: 10rem;"
                       class="mb-2"
                      >
                      <b-button @click="removeImage(item._id)" variant="danger">Delete</b-button>
                      </b-card>
                      </div>

                    </b-card-group>

                      </div>
                    </b-form-group>


                    <b-form-group  label-cols-lg="0">
                      <label>
                        Upload Image
                        <small class="color-text-small"
                          >Width 500 x Height 500. Just only one.</small
                        >
                      </label>
             <!--          <vue-dropzone
                        id="form-image"
                        ref="myVueDropzone"
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :state="validateState('image')"
                        aria-describedby="input-3-live-feedback"
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                      ></vue-dropzone>
                      <b-form-invalid-feedback id="input-3-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      > -->
         
                      <vue-dropzone 
                        ref="myVueDropzone" 
                        id="form-image" 
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :useCustomSlot="true" 
                        @vdropzone-success-multiple="vfileAdded" 
                      ></vue-dropzone>
                    </b-form-group>

                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label>Since</label>
                      <b-form-input
                        id="since"
                        v-model="form.since"
                        placeholder="Enter since "
                        name="since"
                        type="number"
                        :min="0"
                      ></b-form-input>
                      </b-col>
    
                      <b-col cols="6" class="mb-3">
                        <label>Gym style</label>
                        <b-form-input
                        id="qym_style"
                        v-model="form.detail.qym_style "
                        placeholder="Enter style "
                        name="qym_style"
                        type="text"
                      ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-form-group label-cols-lg="0">
                      <label>City</label>
                      <b-form-input
                        id="city"
                        v-model="form.detail.city "
                        placeholder="Enter city "
                        name="city"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
   
                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Price:</label>
                        <b-form-input
                        id="price"
                        v-model="form.detail.price "
                        placeholder="Enter price "
                        name="price"
                        type="number"
                        :min="0"
                      ></b-form-input>
                      </b-col>
    
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Second Price:</label>
                        <b-form-input
                        id="price"
                        v-model="form.detail.second_price "
                        placeholder="Enter second price "
                        name="second_price"
                        type="number"
                        :min="0"
                        :disabled= status.second_price
                      ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-form-group label-cols-lg="0">
                      <label>Latitude</label>
                      <b-form-input
                        id="latitude"
                        v-model="form.latitude "
                        placeholder="Enter latitude "
                        name="latitude"
                        type="number"
                        step="any"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>longitude</label>
                      <b-form-input
                        id="Longitude"
                        v-model="form.longitude "
                        placeholder="Enter longitude "
                        name="longitude"
                        type="number"
                        step="any"
                      ></b-form-input>                  
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Phone</label>
                      <b-form-input
                        id="call"
                        v-model="form.call"
                        placeholder="Enter call "
                        name="call"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Website</label>
                      <b-form-input
                        id="website"
                        v-model="form.website"
                        placeholder="Enter website "
                        name="website"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <div>
                      <b-form-group label-cols-lg="0">
                      <label></label>
                      <b-form-checkbox
                       id="checkbox-1"
                       v-model="form.recommend"
                       name="checkbox-1">
                      recommend gym
                     </b-form-checkbox>
                    </b-form-group>
              <!--       <b-form-checkbox-group
                      v-model="form.facility"
                      :options="form.options"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      :checked="value"
                    ></b-form-checkbox-group>
                    <div class="mt-3">Selected: <strong>{{ form.facility }}</strong></div> -->                   
                    <div id='example-33'>
                    <b-form-group label-cols-lg="0">
                    <label>environment</label>
                     <b-row class="ml-2">
                      <div v-for="(item, index) in this.form.env_option" :key="index">
                       <input type="checkbox" :id="item.name" v-model="item.checked">
                       <label class="mr-2" :for="item.name"> {{ item.name }}</label>
                      </div>
                     </b-row>
                    </b-form-group>
                    </div>

                    <div id='example-3'>
                    <b-form-group label-cols-lg="0">
                    <label>facility</label>
                    <b-row class="ml-2">
                     <div v-for="(item, index) in this.form.option" :key="index">
                     <input type="checkbox" :id="item.name" v-model="item.checked">
                     <label class="mr-2" :for="item.name"> {{ item.name }}</label>
                     </div>
                    </b-row>                  
                    </b-form-group>
                  </div>
                  </div>
        
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>

   
                    <b-form-group>
                      <router-link to="/gym">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}
</style>
